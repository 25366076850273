.timer {
  background-color: red;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;

  button {
    padding: 0.5em;
    background-color: var(--main-color);
    color: white;
    border-radius: 0.5em;
    border: none;
    box-shadow: 0 0 0.05em 0.05em rgba(0, 0, 0, 0.2);

    &.edit {
      font-size: 0.75em;
    }
  }

  div {
    display: flex;
    gap: 0.5em;
  }
}
