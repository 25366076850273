.calendar-day {
  padding: 1em;
  $color-primary: var(--color-primary);
  $color-secondary: var(--color-secondary);
  $color-base: var(--color-base);
  display: flex;
  gap: 1em;
  flex-direction: column;
  background-color: inherit;
  position: relative;
  overflow: auto;

  h1 {
    font-size: 1.5em;
  }

  & > button {
    background-color: $color-secondary;
    color: white;
    aspect-ratio: 1;
    font-size: 1.25em;
    padding: 0.25em;
    border-radius: 50%;
    border: none;
    position: absolute;
    right: 1em;
  }

  & > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2em;
    align-items: flex-start;
    justify-content: center;
    background-color: inherit;
    height: 100%;

    div.todolist {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 1em;
      border-radius: 0.25em;

      h2 {
        font-size: 1.25em;
      }

      ul {
        grid-column: 1 / span 2;
        list-style: none;
        gap: 5px;
        display: grid;
        flex-direction: column;
      }
    }

    div.log {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      gap: 1em;
      background-color: inherit;
      align-items: center;
      justify-content: center;

      & > span {
        background-color: inherit;
        display: grid;
        gap: 1em;
        text-align: center;
        padding: 0 25%;

        p {
          font-size: 1em;
        }
      }
    }

    @media only screen and (max-width: 800px) {
      & {
        grid-template-columns: 1fr;
      }
    }
  }
}
