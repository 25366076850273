.progress {
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  border-radius: 50%;
  --private-degree: calc((1 - var(--degree)) * 360deg);
  background: conic-gradient(
    rgba(170, 240, 170, 0.25) var(--private-degree),
    rgb(170, 240, 170) var(--private-degree) 360deg
  );
  background-color: inherit;
  transition: all 0.5s ease-in-out;
  --content: var(--test);
  content: "hello";

  &::before {
    content: "";
    width: 50%;
    aspect-ratio: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    background-color: inherit;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  &[data-showPercentage="true"]::before {
    content: attr(data-time);
    width: 75%;
  }

  &[data-active="true"] {
    animation: pulse 2s ease-in-out infinite;

    &::before {
      animation: pulse 2s ease-in-out infinite;
    }
  }

  @keyframes pulse {
    0% {
    }

    50% {
      box-shadow: 0 0 0.5em 0.5em rgb(170, 240, 170, 0.5);
    }

    100% {
    }
  }
}
