section#skills {
  $color-base: var(--color-base);
  $color-primary: var(--color-primary);
  $color-highlight: var(--main-color);
  $color-text-primary: var(--color-text-primary);
  $color-text-secondary: var(--color-text-secondary);
  padding: 2em 0;
  display: grid;

  header {
    text-align: center;
    padding: 2em;

    h2 {
      font-size: 1em;
      color: $color-text-secondary;
    }

    h1 {
      font-size: 1.5em;
      color: $color-highlight;
    }
  }

  & > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    justify-self: center;
    transition: all 0.5s;

    @media screen and (max-width: 800px) {
      grid-template-columns: 1fr;
    }

    & > div {
      display: grid;
      background-color: $color-base;
      border-radius: 0.5em;
      grid-template-rows: auto 1fr;
      gap: 1em;
      padding: 1em;
      font-size: 1.5em;

      @media screen and (max-width: 300px) {
        font-size: 1em;
      }

      h1 {
        color: $color-text-secondary;
        font-size: 1em;
        text-align: center;
      }

      ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        list-style: none;
        gap: 1em;

        li {
          display: grid;
          grid-template-columns: auto 1fr;
          column-gap: 0.5em;
          row-gap: 0.15em;

          i {
            font-style: normal;
            font-family: "Material Icons";
            color: $color-highlight;
            place-self: center;
          }

          h2 {
            color: $color-text-secondary;
            font-size: 0.75em;
          }

          p {
            grid-column: 2;
            color: $color-text-primary;
            font-size: 0.5em;
          }

          div {
            position: relative;
            display: flex;
            height: 0.25em;
            width: 100%;
            background-color: $color-primary;
            grid-column: 2;
            border-radius: 0.25em;
            --level: 0;

            &::before {
              content: "";
              height: 100%;
              width: calc(var(--level) * 1%);
              background-color: $color-text-primary;
              border-radius: inherit;
            }

            &::after {
              counter-reset: variable calc(var(--level) * 1);
              content: counter(variable);
              position: absolute;
              display: flex;
              place-items: center;
              place-content: center;
              line-height: 0;
              padding: 0.5em;
              aspect-ratio: 1;
              color: $color-primary;
              color: $color-text-primary;
              background-color: $color-text-primary;
              background-color: $color-highlight;
              border-radius: 50%;
              font-size: 0.15em;
              top: 50%;
              left: calc(var(--level) * 1%);
              transform: translate(-50%, -50%) scale(0);
              transition: transform 0.5s ease-in-out;
              box-shadow: 0 0 0.25em 0.25em rgba(0, 0, 0, 0.2);
            }
          }

          
          &:hover div::after {
            transform: translate(-50%, -50%) scale(1);
          }
        }
      }
    }
  }
}
