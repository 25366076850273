section#projects {
  $color-base: var(--color-base);
  $color-primary: var(--color-primary);
  $color-highlight: var(--main-color);
  $color-text-primary: var(--color-text-primary);
  $color-text-secondary: var(--color-text-secondary);
  padding: 2em 0;
  display: grid;

  header {
    text-align: center;
    padding: 2em;

    h2 {
      font-size: 1em;
      color: $color-text-secondary;
    }

    h1 {
      font-size: 1.5em;
      color: $color-highlight;
    }
  }

  div.unreleased {
    place-self: center;
    background-color: $color-base;
    padding: 1em;
    border-radius: 0.25em;
    display: flex;
    align-items: center;
    gap: 0.5em;
    font-size: 1.5em;
    box-shadow: 0 0 0.25em 0.25em rgba(0, 0, 0, 0.1);

    &::before {
      content: "construction";
      font-family: "Material Icons";
    }

    &::after {
      content: "lock";
      font-family: "Material Icons";
    }
  }

  div {
    position: relative;
    display: grid;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr;

    ul {
      place-self: center;
      display: grid;
      list-style: none;
      grid-template-columns: repeat(10, 1fr);
      gap: 1em;
      overflow-x: auto;
      padding: 1em;

      li {
        background-color: $color-primary;
        padding: 2em;
        border-radius: 0.25em;
        aspect-ratio: 1;
        box-shadow: 0 0 0.25em 0.25em rgba(0, 0, 0, 0.2);
      }
    }

    button {
      display: none;
      aspect-ratio: 1;
      padding: 1em;
    }
  }
}
