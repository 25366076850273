#showfeur-login-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  gap: 1em;
  background-color: #333;
  font-size: 1.25em;

  img {
    width: 300px;
    pointer-events: none;
    transform-origin: center bottom;
    animation: wiggle 10s ease-in-out 1s infinite;
  }

  @keyframes wiggle {
    0% {
      transform: skewX(0deg);
    }
    5% {
      transform: skewX(-2.5deg);
    }

    10% {
      transform: skewX(2.5deg);
    }

    15% {
      transform: skewX(0deg);
    }
  }

  .myinput {
    color: white;
  }

  button {
    background-color: var(--main-color);
    padding: 1em 2em;
    color: white;
    border-radius: 1em;
    border: none;
    font-size: 0.75em;
  }

  a {
    color: white;
    text-decoration: none;
    font-size: 0.75em;
  }
}
