.dropdown {
  background-color: white;
  position: relative;

  --color-dropdown: #eee;
  $color-dropdown: var(--color-dropdown);
  --color-background: white;
  $color-background: var(--color-background);
  --color-background-hover: #eee;
  $color-background-hover: var(--color-background-hover);

  & > button {
    background-color: inherit;
    border: 2px solid $color-dropdown;
    width: 100%;
    font-size: 1em;
    padding: 0.5em;
    text-align: left;
    box-shadow: 0 0 0.1em 0.1em rgba(0, 0, 0, 0.1);
    text-transform: capitalize;

    &::before {
      content: "Sort: ";
    }
  }

  & > ul {
    position: absolute;
    list-style: none;
    background-color: white;
    box-shadow: 0 0 0.1em 0.1em rgba(0, 0, 0, 0.1);
    z-index: 10;
    width: 100%;
    top: calc(100% + 0.25em);
    display: flex;
    flex-direction: column;
    transform-origin: top;
    transition: transform 0.5s ease-in-out;

    li {
      button {
        border: none;
        padding: 0.5em;
        font-size: 1em;
        background: none;
        width: 100%;
        text-align: left;
        text-transform: capitalize;

        &:hover {
          background-color: $color-background-hover;
        }
      }

      &:not(&:last-of-type) {
        color: red;
        border-bottom: 1px solid $color-dropdown;
      }
    }

    &[data-open="false"] {
      transform: rotateX(-90deg);
    }
  }
}
