div#LoginPage {
  $color-base: var(--color-base);
  $color-primary: var(--color-primary);
  $color-highlight: var(--color-highlight);
  $color-text-primary: var(--color-text-primary);
  $color-text-secondary: var(--color-text-secondary);

  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5em;

  h1 {
    color: $color-text-primary;
  }

  form {
    position: relative;
    display: grid;
    gap: 1em;
    width: 25%;

    --myinput-color-base: var(--color-text-secondary);
    --myinput-color-primary: var(--main-color);
    --myinput-color-text: var(--color-text-primary);
    --myinput-color-background: var(--color-primary);

    input[type="submit"] {
      padding: 0.5em 1em;
      border: none;
      font-size: 1em;
      background-color: $color-highlight;
      border-radius: 0.25em;
      color: $color-primary;
      place-self: center;
    }

    p {
      position: absolute;
      color: white;
      top: calc(100% + 1em);
      left: 50%;
      transform: translateX(-50%);
      background-color: $color-primary;
      border-radius: 0.25em;
      padding: 0.5em;
      width: fit-content;
      white-space: nowrap;
      animation: appear 2.5s ease-in-out 2 alternate both;
    }

    @keyframes appear {
      0% {
        opacity: 0;
      }

      10% {
        opacity: 1;
      }

      100% {
        opacity: 1;
      }
    }
  }
}
