.minesweeper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 500px;

  header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button {
      border: none;
      background-color: white;
      color: var(--main-color);
      padding: 0.5em;
      border-radius: 1em;
    }

    p {
      background-color: var(--main-color);
      color: white;
      padding: 0.5em 1em;
    }
  }

  .board {
    display: grid;
    grid-template-columns: repeat(var(--board-width), 1fr);
    gap: 0.25em;
    width: 100%;
    // min-width: 100%;

    button {
      border-radius: 0;
      font-size: clamp(5px, calc(250px / var(--board-width)), 5em);
      width: 100%;
      aspect-ratio: 1;
      border: none;
      line-height: 0em;
      color: white;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: background-color 0.25s ease-in-out;
      box-shadow: 0 0 5px 5px rgba(212, 141, 141, 0.1);

      @mixin symbol($color, $content) {
        background-color: $color;

        &::after {
          content: $content;
          font-family: "Material Icons";
          line-height: 0em;
        }
      }

      &.open {
        background-color: var(--main-color);
      }

      &.flagged {
        @include symbol(#50c878, "flag");
      }

      &.mine {
        @include symbol(tomato, "error");
      }
    }

    &[data-active="false"] {
      button.flagged {
        &.mine {
          background-color: #50c878;
        }

        &:not(.mine)::after {
          content: "close";
        }
      }
    }

    &[data-winner="true"] {
      button {
        background-color: #50c878;
      }
    }

    &[data-game-over="true"] {
      pointer-events: none;
    }
  }
}
