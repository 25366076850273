li.calendar-preview {
  $color-primary: var(--color-primary);
  $color-secondary: var(--color-secondary);
  $color-base-shade: var(--color-base-shade);

  border: 1px solid $color-base-shade;
  padding: 0.5em;

  & > a {
    text-decoration: none;
    font-size: 0.75em;
    height: 1.75em;
    aspect-ratio: 1;
    display: grid;
    justify-content: center;
    align-items: center;
    place-self: flex-start;
    border-radius: 50%;
    transition: all 0.25s ease-in-out;

    &:hover {
      background-color: $color-base-shade;
    }
  }

  &.today > a {
    background-color: $color-secondary;
    color: white;
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.25em;
    margin-top: 0.5em;
    overflow: hidden;

    li {
      display: block;
      text-align: left;
      border-radius: 0.25em;
      padding: 0.15em 0.5em;
      border: none;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      font-size: 0.65em;
      color: #444;

      &.done {
        text-decoration: line-through;
        color: #888;
      }
    }

    a {
      font-size: 0.6em;
      text-decoration: none;
      color: $color-primary;
    }
  }

  &:first-of-type {
    grid-column-start: var(--start);
  }

  a.more {
    font-size: 0.5em;
    white-space: nowrap;
    overflow: hidden;
  }
}
