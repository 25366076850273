a.show {
  position: relative;
  overflow: hidden;
  width: 150px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  color: white;
  gap: 0.5em;
  text-align: center;
  transform-style: preserve-3d;
  background-color: blue;

  img {
    width: 100%;
    pointer-events: none;
    border-radius: 0.5em;
  }

  button {
    position: absolute;
    right: 0;
    border: none;
    opacity: 0.75;
    background-color: #666;
    border-color: #666;
    color: white;
    transition: 0.25s ease-in-out;
    padding: 0.5em;
    font-size: 1em;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    aspect-ratio: 1;

    &:hover {
      background-color: #aaa;
    }

    &.watched {
      background-color: var(--main-color);
      opacity: 1;
    }
  }
}
