section#about {
  $color-base: var(--color-base);
  $color-primary: var(--color-primary);
  $color-highlight: var(--main-color);
  $color-text-primary: var(--color-text-primary);
  $color-text-secondary: var(--color-text-secondary);
  padding: 2em 0;

  header {
    text-align: center;
    padding: 2em;

    h2 {
      font-size: 1em;
      color: $color-text-secondary;
    }

    h1 {
      font-size: 1.5em;
      color: $color-highlight;
    }
  }

  & > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 5%;

    @media screen and (max-width: 1000px) {
      grid-template-columns: 1fr;
      gap: 1em;
    }

    span {
      max-width: 300px;
      width: 50%;
      margin-bottom: 2em;
      background: radial-gradient($color-highlight 75%, transparent);
      transform: rotate(-10deg);
      place-self: center;
      justify-self: center;
      align-self: center;
      border-radius: 15%;

      img {
        width: 100%;
        transform: rotate(10deg);
        border-radius: inherit;
        pointer-events: none;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 2em;

      ul {
        list-style: none;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
        gap: 0.5em; 

        @media screen and (max-width: 300px) {
          font-size: 0.75em;
          // grid-template-columns: 1fr;
        }

        @media screen and (max-width: 400px) {
          font-size: 0.75em;
        }

        li {
          display: grid;
          grid-template-rows: repeat(3, 1fr);
          flex-direction: column;
          border-radius: 0.5em;
          background-color: $color-base;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: 1em;

          i {
            font-family: "Material Icons";
            color: $color-highlight;
            font-style: normal;
            font-size: 2em;
          }

          h1 {
            color: $color-text-secondary;
            font-size: 1em;
          }

          p {
            padding: 0;
            color: $color-text-primary;
            font-size: 0.75em;
          }
        }
      }

      p {
        color: $color-text-primary;
      }

      a {
        padding: 0.5em 1em;
        border: 1px solid $color-highlight;
        border-radius: 0.25em;
        color: $color-highlight;
        font-weight: 450;
        font-size: 1em;
        text-decoration: none;
        background-color: $color-highlight;
        color: $color-primary;
        width: fit-content;
      }
    }
  }
}
