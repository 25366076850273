div.show-model {
  background-color: #888;
  width: fit-content;
  max-width: 500px;
  aspect-ratio: 2/1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-25%);
  display: grid;
  padding: 0;
  grid-template-columns: 1fr 3fr;
  border-radius: 0.5em;
  box-shadow: 0 0 0.25em 0.25em rgba(0, 0, 0, 0.25);
  color: white;

  header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #666;
    color: white;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    box-shadow: inherit;

    img {
      height: 100px;
      border-radius: 0.5em;
      box-shadow: inherit;
    }

    h1 {
      //   grid-area: title;
      font-size: 1em;
      padding: 0.5em;
      text-align: center;
    }

    p {
      //   grid-area: info;
      padding: 0.5em;
      font-size: 0.75em;
    }
  }

  & > ul {
    overflow-y: auto;
    height: 100%;
    padding: 1em;

    li {
      display: flex;
      gap: 0.5em;
      flex-direction: column;
      h2 {
        font-size: 1em;
      }

      & > ul {
        list-style: none;
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        gap: 5px;
        padding-bottom: 1em;

        button {
          border: 1px solid white;
          color: white;
          aspect-ratio: 1;
          background: none;
          border-radius: 0.5em;
          box-shadow: 0 0 0.1em 0.1em rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  & > button {
    background-color: var(--main-color);
    color: white;
    padding: 0.25em;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    transform: translate(25%, -25%);
    box-shadow: inherit;
  }
}
