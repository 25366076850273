nav#showfeur-nav {
  padding: 1em 2em;
  background-color: #222;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  a {
    img {
      width: 50px;
    }
  }

  p {
    color: white;
    background-color: var(--main-color);
    display: flex;
    place-items: center;
    place-content: center;
    gap: 1em;
    padding: 0.5em 1em;
    border-radius: 0.5em;

    &::before {
      content: "schedule";
      font-family: "Material Icons";
      font-size: 1.5em;
    }
  }

  button {
    background: none;
    border: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5em;
    font-size: 1em;

    &::before {
      content: "person";
      font-family: "Material Icons";
      font-size: 1.5em;
    }
  }
}
