a.movie {
  position: relative;
  overflow: hidden;
  width: 150px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  color: white;
  gap: 0.5em;
  text-align: center;

  img {
    width: 100%;
    pointer-events: none;
    border-radius: 0.5em;
  }

  button {
    position: absolute;
    right: 0;
    border: none;
    opacity: 0.75;
    background-color: #666;
    border-color: #666;
    color: white;
    transition: 0.25s ease-in-out;
    padding: 0.25em;

    &::before,
    &::after {
      content: "";
      position: absolute;
      border: 1em solid transparent;
      border-left-color: inherit;
      top: 100%;
      left: 0;
      width: 25%;
      transform: translateY(-50%);
      transition: inherit;
    }

    &::after {
      left: initial;
      right: 0;
      border-color: transparent;
      border-right-color: inherit;
    }

    &:hover {
      background-color: #aaa;

      &::before {
        border-left-color: #aaa;
      }

      &::after {
        border-right-color: #aaa;
      }
    }

    &.watched {
      background-color: var(--main-color);
    }
  }
}
