@media (prefers-color-scheme: light) {
  :root {
    --color-base: #eeeeee;
    --color-primary: #fff;
    --color-highlight: var(--main-color);
    --color-text-primary: #000;
    --color-text-secondary: #111;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-base: #222;
    --color-primary: #333;
    --color-highlight: var(--main-color);
    --color-text-primary: #fff;
    --color-text-secondary: #eee;
  }
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  --border-radius: 20px;
  margin: 0;
  /* margin-bottom: 50px; */
  background-color: #333;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  $color-base: var(--color-base);
  $color-primary: var(--color-primary);
  $color-highlight: var(--color-highlight);
  background: repeating-linear-gradient(
    to right,
    $color-base 0.5%,
    $color-primary 0.75%,
    $color-primary 1%
  );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slide {
  position: relative;
  overflow: hidden;
}

.slide::before {
  content: "";
  position: absolute;
  width: 0%;
  height: 100%;
  left: 0%;
  top: 0%;
  background-color: white;
  opacity: 0.25;
  /* background-color: rgba(255, 255, 255, 0.25); */
  border-radius: inherit;
  transition: all 500ms;
}

.slide:hover::before {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.center::before {
  height: 0%;
  left: 50%;
  top: 50%;
}

a,
button,
input[type="submit"] {
  transition: filter 0.5s ease-in-out;
  &:hover {
    cursor: pointer;
    filter: brightness(1.5);
  }
}
