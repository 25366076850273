.schedule {
  background-color: white;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;

  $color-base-shade: var(--color-base-shade);
  border-left: 2px solid $color-base-shade;

  header {
    display: flex;
    gap: 1em;
    align-items: center;
    border-bottom: 2px solid $color-base-shade;
    padding: 1em;

    h1 {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1em;
      height: 100%;
      font-weight: normal;
      font-size: 1.5em;
    }

    a {
      background: none;
      border: none;
      color: black;
      text-decoration: none;
      transition: background-color 0.25s ease-in-out;

      &:hover {
        background-color: $color-base-shade;
      }
    }

    span {
      display: flex;
      gap: 0.5em;

      a {
        border-radius: 50%;
      }
    }

    & > a {
      border: 1px solid $color-base-shade;
      border-radius: 0.25em;
      padding: 0.5em 0.75em;

      &:nth-of-type(2) {
        justify-self: flex-end;
      }
    }
  }

  & > ul {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(7, 14.29%);
    grid-auto-rows: 1fr;
    overflow: scroll;
  }
}
