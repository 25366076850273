section.contact {
  $color-base: var(--color-base);
  $color-primary: var(--color-primary);
  $color-highlight: var(--main-color);
  $color-text-primary: var(--color-text-primary);
  $color-text-secondary: var(--color-text-secondary);
  $color-highlight: var(--main-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2em;

  header {
    text-align: center;
    padding: 2em;

    h2 {
      font-size: 1em;
      color: $color-text-secondary;
    }

    h1 {
      font-size: 1.5em;
      color: $color-highlight;
    }
  }

  form {
    --myinput-color-base: var(--color-text-secondary);
    --myinput-color-primary: var(--main-color);
    --myinput-color-text: var(--color-text-primary);
    --myinput-color-background: var(--color-primary);
    --myinput-height: 200px;
    display: grid;
    width: 50%;
    gap: 2em;

    input {
      &::-webkit-textfield-decoration-container {
        color: white;
        fill: white;
      }
    }

    input[type="submit"] {
      padding: 1em;
      background: none;
      border-radius: 0.25em;
      color: $color-highlight;
      font-weight: 450;
      font-size: 1em;
      text-decoration: none;
      transition: all 0.5s ease-in-out;
      background-color: $color-highlight;
      color: $color-primary;
      width: fit-content;
      border: none;
    }

    @media screen and (max-width: 600px) {
      width: 75%;
    }
  }
}
