.topic {
  --color-topic: red;
  $color-topic: var(--color-topic);
  $color-base-shade: var(--color-base-shade);

  & > button {
    background-color: $color-topic;
    border: none;
    text-align: left;
    width: 100%;
    font-size: 1em;
    padding: 0.5em;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    box-shadow: 0 0 0.1em 0.1em rgba(0, 0, 0, 0.1);
    color: white;
    border-radius: 0.25em;
    gap: 1em;

    button {
      font-style: normal;
      background-color: white;
      border: none;
      border-radius: 0.25em;
      aspect-ratio: 1;
      font-size: 1em;
      padding: 0.35em;
      display: flex;
      color: $color-topic;
      font-family: "Material Icons";
      transition: background-color 0.25s ease-in-out;

      &:hover {
        background-color: $color-base-shade;
      }

      &.skill0::before {
        content: "star_border";
      }

      &.skill1::before {
        content: "star_half";
      }

      &.skill1::before {
        content: "star";
      }
    }
  }
}
