div.snake-game {
  --snake-main-color: var(--main-color);
  display: flex;
  height: fit-content;
  width: fit-content;
  gap: 25px;
  flex-direction: column;

  .game-board {
    position: relative;
    display: grid;
    width: calc(var(--board-width) * 10px);
    height: calc(var(--board-height) * 10px);
    grid-template-columns: repeat(var(--board-width), 1fr);
    grid-template-rows: repeat(var(--board-height), 1fr);
    background-color: #ccc;
    gap: 0;

    .snake {
      background-color: var(--snake-main-color);
      border: 1px solid black;
      width: 10px;
      height: 10px;
    }

    &.active {
      .food {
        background-color: yellow;
        border: 1px solid black;
        width: 100%;
        height: 100%;
      }
    }
  }
}
