#alphabet-game {
  $shadow: 0 0 5px 2.5px
    rgba(
      $color: #ddd,
      $alpha: 0.5,
    );

  background-color: white;
  font-size: 2em;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-areas:
    "next next"
    "entry reset"
    "timer timer"
    "history history";
  grid-template-columns: 3fr 1fr;
  width: 90%;
  max-width: 600px;
  gap: 0.5em;
  border-radius: 1em;
  padding: 1em;

  p:nth-of-type(1) {
    grid-area: next;
    font-size: 1.5em;
    text-align: center;
  }

  input {
    grid-area: entry;
    font-size: 0.75em;
    padding: 0.5em;
    outline: none;
    border: 2px solid #ddd;
    transition: border 0.5s;

    &:focus {
      border: 2px solid var(--main-color);
    }
  }

  button.reset {
    grid-area: reset;
    height: 100%;
    border: none;
    background-color: var(--main-color);
    color: white;
    font-size: 0.6em;
    letter-spacing: 0.1em;
    box-shadow: $shadow;
  }

  button.score {
    position: relative;
    grid-area: timer;
    background-color: tomato;
    box-shadow: $shadow;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1em;
    padding: 0.5em;
    border: none;

    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      padding: 0.5em 0.75em;
      transform: translate(-50%, -50%);
      background: none;
      border: 1px solid white;
      color: white;
      font-size: 0.5em;
      border-radius: 50%;
    }
  }

  ul.history {
    grid-area: history;
    display: grid;
    transition: all 0.5s;
    transform-origin: top left;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0.25em;

    &[data-show="false"] {
        display: none;
    }

    li {
      padding: 0;
      font-size: 0.5em;
      width: 100%;
    }
  }
}
