.task {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.25em;
  transition: all 0.5s ease-in-out;

  input,
  button {
    padding: 0.5em;
    font-size: 1em;
    text-align: left;
    background-color: lightblue;
    border-radius: 0.25em;
    transition: all 0.5s ease-in-out;
    border: none;

    &:nth-of-type(2) {
      border-radius: 0.25em;
      width: fit-content;
    }
  }
}

[data-edit="false"] {
  .task {
    input {
      display: none;
    }

    & > button {
      display: block;

      &:nth-of-type(2) {
        display: none;
      }

      &.isDone {
        text-decoration: line-through;
      }
    }
  }
}

[data-edit="true"] {
  .task {
    input {
      display: block;
    }

    & > button {
      &:first-of-type {
        display: none;
      }

      &:nth-of-type(2) {
        display: block;
      }
    }
  }
}
