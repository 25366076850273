.myinput {
  $color-base: var(--myinput-color-base);
  $color-primary: var(--myinput-color-primary);
  $color-background: var(--myinput-color-background);
  $color-text: var(--myinput-color-text);
  $input-height: var(--myinput-height);
  position: relative;
  height: fit-content;
  background-color: inherit;

  input,
  textarea {
    font-family: "Avenir";
    background: transparent;
    border: 2px solid $color-base;
    border-radius: 0.5rem;
    padding: 15px;
    color: $color-text;
    font-size: 1em;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    outline: none;
    transition: border-color 0.5s;

    &:focus {
      border-color: $color-primary;

      & ~ label {
        color: $color-primary;
      }
    }

    &:focus ~ label,
    &:not(:placeholder-shown):not(:focus) ~ label {
      top: 0;
      transform: translateY(-50%) scale(0.9);
      background: $color-background;
      // background-color: red;
    }
  }

  label {
    position: absolute;
    left: 1em;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    transform-origin: left center;
    transition: all 0.5s ease-in-out;
    background-color: inherit;
    height: 5px;
    padding: 0 0.15em;
    display: flex;
    align-items: center;
    color: $color-text;
    white-space: pre;
  }

  textarea {
    height: $input-height;
    resize: none;

    & ~ label {
      top: 1.5em;
    }
  }
}
