footer#footer {
  background-color: var(--main-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  font-size: 0.75em;

  a {
    text-decoration: none;
    color: white;
    padding: 0 0.25em;
  }
}
