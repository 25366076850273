nav.mcat-navigation {
  position: fixed;
  top: 0;
  right: 0;
  height: fit-content;
  z-index: 10;
  $color-primary: var(--color-secondary);

  ul {
    position: absolute;
    list-style: none;
    display: flex;
    flex-direction: row;
    gap: 1em;
    right: 0;
    margin: 15px;

    a,
    button {
      background-color: $color-primary;
      place-content: center;
      place-items: center;
      border: none;
      display: flex;
      padding: 0.75em;
      font-family: "Material Icons";
      text-decoration: none;
      color: white;
      border-radius: 50%;
      font-size: 1.25em;
      box-shadow: 0 0 0.1em 0.1em rgba($color: black, $alpha: 0.1);
      transition: all 0.5s;
    }

    button.menu {
      position: relative;
      padding: 1.25em;
      div {
        position: absolute;
        border-radius: 5px;
        background-color: transparent;
        width: 50%;
        height: 2.5px;
        transition: background-color 0.25s;

        &::before,
        &::after {
          content: "";
          width: 100%;
          height: inherit;
          position: absolute;
          border-radius: inherit;
          left: 0;
          background-color: white;
          transition: all 0.5s;
          transform: rotate(45deg);
        }

        &::after {
          transform: rotate(-45deg);
        }
      }
    }
  }

  &[data-open="false"] {
    a {
      transform: translateY(-200%);
    }

    button.menu {
      div {
        background-color: white;
        &::before {
          transform: translateY(-0.35em);
        }
        &::after {
          transform: translateY(0.35em);
        }
      }
    }
  }
}
