#match-game {
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 1em;
  justify-content: space-between;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      background-color: var(--main-color);
      color: white;
      padding: 0.5em 1em;
    }

    button {
      color: var(--main-color);
      background-color: white;
      border: none;
      border-radius: 50%;
      aspect-ratio: 1/1;
      padding: 0.5em;
      transform: translate(25%);
    }
  }

  .board {
    display: grid;
    grid-template-columns: repeat(var(--width), 1fr);
    gap: 0.5em;

    button {
      font-family: "Material Icons";
      font-size: clamp(5px, calc(250px / var(--width)), 5em);
      aspect-ratio: 1;
      transition: all 0.5s;
      border: none;
      background-color: var(--main-color);
      border-radius: 0.35em;
      color: transparent;
      transform: rotateY(180deg);

      &.flipped {
        background-color: white;
        transform: rotateY(0deg);
        color: var(--main-color);
      }
    }

    &[data-winner="true"] {
      button {
        background-color: #50c878;
        color: white;
      }
    }
  }
}
