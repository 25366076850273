.blur {
  animation: blur 1s ease-in-out 0s 1 normal both;
}

@keyframes blur {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.slide {
  animation: slide 0.5s ease-in-out 0s 1 normal both;
}

@keyframes slide {
  from {
    transform: translateY(25%);
  }

  to {
    transform: translateY(0%);
  }
}
