html {
  --main-color: #0080ff;
  --nav-height: 112px;
}

body {
  font-family: "Avenir", Arial;
}

button:hover {
  cursor: pointer;
}

.bigThing {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  background-color: white;
}
