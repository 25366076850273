#game-page {
  height: 100vh;
  display: flex;
  place-content: center;
  place-items: center;
  flex-direction: column;

  & > a {
    background-color: var(--main-color);
    border: none;
    position: absolute;
    left: 1em;
    top: 1em;
    padding: 0.5em;
    color: white;
    border-radius: 50%;
    text-decoration: none;
  }

  .game {
    position: relative;
    height: 80vh;
    width: 100%;
    justify-content: center;
    display: flex;
    gap: 2em;

    .inputs {
      background-color: #333;
      place-self: center;
    }
  }
}
