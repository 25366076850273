.wordle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  .board {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 65%;
    gap: 1em;

    label {
      color: white;
      font-size: 1.5em;
      opacity: 0;
      animation: fade 3s ease-in-out;
      animation-iteration-count: 1;
      position: absolute;
      background-color: black;
      border-radius: 0.5em;
      padding: 0.5em 1em;
      left: 50%;
      transform: translate(-50%, -100px);
    }

    @keyframes fade {
      25%,
      50% {
        opacity: 1;
        transform: translateY(-50%, 0px);
      }
    }

    p {
      border: 2px solid #888;
      color: white;
      font-weight: bold;
      aspect-ratio: 1;
      display: flex;
      place-content: center;
      place-items: center;
      font-size: 2em;
      text-transform: uppercase;
      transition: all 0.5s ease-in-out;

      &.correct {
        background-color: #50c878;
        border: none;
      }

      &.includes {
        background-color: goldenrod;
        border: none;
      }

      &.incorrect {
        background-color: #888;
        border: none;
      }
    }
  }

  input {
    opacity: 0;
    position: absolute;
    left: -1000px;
  }

  .keyboard {
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    gap: 0.25em;

    &[data-loser="true"] {
      background-color: red;
    }

    button {
      grid-column: span 2;
      padding: 1em 1em;
      border: none;
      font-size: 1em;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.5em;
      text-transform: uppercase;
      grid-column: span 2;
      color: white;
      background-color: #777;

      &:nth-of-type(11) {
        grid-column: 2 / span 2;
      }

      &:nth-of-type(20),
      &:nth-of-type(28) {
        grid-column: span 3;
      }

      &:nth-of-type(28) {
        text-transform: lowercase;
        font-family: "Material Icons";
      }


      &.guessed {
        background-color: #444;
      }

      &.includes {
        background-color: goldenrod;
      }

      &.correct {
        background-color: #50c878;
      }
    }
  }
}
