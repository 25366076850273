#AuthPage {
  $color-primary: var(--color-primary);
  $color-secondary: var(--color-secondary);
  $color-base: var(--color-base);

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  gap: 1em;
  background: $color-base;

  img {
    height: 100px;
  }

  h1 {
    color: $color-primary;
  }

  .myinput {
    --myinput-color-base: var(--color-base-shade);
    --myinput-color-primary: var(--color-secondary);
    --myinput-color-text: black;
  }

  button {
    background-color: $color-secondary;
    padding: 1em;
    aspect-ratio: 1 / 0.5;
    color: white;
    border: none;
    border-radius: 0.5em;
    font-size: 1em;
  }
}
