section#introduction {
  position: relative;
  $color-base: var(--color-base);
  $color-primary: var(--color-primary);
  $color-highlight: var(--main-color);
  $color-text-secondary: var(--color-text-secondary);

  position: relative;
  height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
  place-items: center;
  text-align: center;
  flex-direction: column;

  @media screen and (max-width: 400px) {
    font-size: 0.5em;
  }

  i {
    font-style: normal;
    transition: all 0.5s;
    color: var(--main-color);
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 1em;

    @media screen and (max-width: 400px) {
      font-size: 0.75em;
    }

    div.titles {
      font-size: 1.5em;
      h2 {
        font-size: 1em;
      }

      h1 {
        font-size: 2em;
      }

      subtitle {
        font-size: 0.75em;
        color: $color-text-secondary;
      }
    }

    div.buttons {
      display: flex;
      gap: 1em;

      a {
        padding: 0.5em 1em;
        border: 1px solid $color-highlight;
        border-radius: 0.25em;
        color: $color-highlight;
        font-weight: 450;
        font-size: 1em;
        text-decoration: none;

        &:nth-of-type(2) {
          background-color: $color-highlight;
          color: $color-primary;
        }
      }
    }
  }

  div.profile {
    position: relative;
    font-size: inherit;
    
    img {
      height: 25rem;
      padding-left: 2em;
      padding-right: 2em;
      border-top-left-radius: 50%;
      border-top-right-radius: 50%;
      background: linear-gradient(to bottom, $color-highlight, transparent);
      pointer-events: none;

      @media screen and (max-width: 400px) {
        height: 40vh;
      }
    }

    &::after {
      position: absolute;
      content: "";
      display: block;
      height: 0px;
      bottom: 0;
      left: 50%;
      width: 62.5%;
      transform: translateX(-47%) translateY(50%);
      box-shadow: 0 0 1px 1px $color-primary;
    }
  }

  @media screen and (max-width: 600px) {
    font-size: 1em;
  }
}
