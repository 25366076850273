.calendar {
  background-color: white;
  gap: 1em;
  display: flex;
  height: fit-content;
  flex-direction: column;
  padding: 1em;
  border-radius: 0.5em;
  // box-shadow: 0 0 0.1em 0.1em rgba(0, 0, 0, 0.1);

  $color-primary: var(--color-primary);
  $color-secondary: var(--color-secondary);
  $color-base-shade: var(--color-base-shade);

  header {
    display: flex;
    gap: 1em;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-size: 1em;
      height: 100%;
      color: $color-primary;
    }

    div {
      display: flex;
      gap: 0.25em;

      button {
        background: none;
        border: none;
        color: $color-primary;
      }
    }
  }

  ul {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(7, 1fr);
    grid-auto-rows: 1fr;
    list-style: none;
    gap: 0.5em;
    font-size: 0.8em;

    li {
      text-align: center;
      color: #666;

      a {
        width: 100%;
        display: flex;
        place-content: center;
        align-items: center;
        text-decoration: none;
        padding: 0.5em;
        color: black;
        aspect-ratio: 1/1;
        line-height: 0;
        border-radius: 50%;
        transition: all 0.25s ease-in-out;

        &:hover {
          background-color: $color-base-shade;
        }

        &.selected {
          background-color: $color-base-shade;
        }

        &.today {
          background-color: $color-secondary;
          color: white;
        }
      }

      &:nth-of-type(8) {
        grid-column-start: var(--start);
      }
    }
  }
}
